export const scrollToHowItWorks = (
  scrollRef: HTMLDivElement | null,
  shouldUseMotion: boolean
): void => {
  if (scrollRef !== null) {
    if (shouldUseMotion) {
      scrollRef.scrollIntoView({
        behavior: "smooth",
      })

      // 1 second should be enough for the smooth scroll to end
      setTimeout(() => {
        scrollRef.focus()
      }, 1000)
    } else {
      scrollRef.focus()
    }
  }
}
